import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import HomePage from "../pages/home/HomePage";
import { PageRoute } from "./routes";
import AboutPage from "../pages/about/AboutPage";
import AppShell from "../components/app_shell/AppShell";
import TermsAndConditionsPage from "../pages/terms/TermsAndConditionsPage";
import PrivacyPolicyPage from "../pages/privacy/PrivacyPolicyPage";
import StandardsPage from "../pages/standards/StandardsPage";
import ContactPage from "../pages/contact/ContactPage";

export const NavigationWrapper = () => {
  return (
    <BrowserRouter>
      <AppShell>
        <Routes>
          <Route path={"/"}>
            <Route index element={<HomePage />} />

            <Route path={PageRoute.ABOUT} element={<AboutPage />} />
            <Route path={PageRoute.CONTACT} element={<ContactPage />} />
            <Route path={PageRoute.PRIVACY} element={<PrivacyPolicyPage />} />
            <Route path={PageRoute.STANDARDS} element={<StandardsPage />} />
            <Route
              path={PageRoute.TERMS}
              element={<TermsAndConditionsPage />}
            />
            {/* for when people link from app */}
            <Route path="/app/*" element={<Navigate to="/" replace />} />
          </Route>
        </Routes>
      </AppShell>
    </BrowserRouter>
  );
};

export default NavigationWrapper;
